import Vue from 'vue';
import { mdiTwitter, mdiGithub, mdiLinkedin } from '@mdi/js';
export default Vue.extend({
    name: 'HomeIntro',
    inject: {
        theme: {
            default: { isDark: false },
        },
    },
    data: function () { return ({
        //    shortDescription: "I am software developer, working for event it AG. I like to spend time with my family in my spare time and build at my smart home to life smarter.",
        shortDescription: "I'm a qualified IT specialist and a fanatic of Web Applications and there Performance. Would you like to find out more about me?",
        socials: [
            {
                icon: "twitter",
                url: "https://twitter.com/joelbladt",
                path: mdiTwitter
            },
            {
                icon: "github",
                url: "https://github.com/joelbladt",
                path: mdiGithub
            },
            {
                icon: "linkedin",
                url: "https://www.linkedin.com/in/joelbladt/",
                path: mdiLinkedin
            }
        ]
    }); },
});
